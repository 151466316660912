.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.side-bar {
  margin-top: 1em;
  margin-left: 25%;
  margin-right: 25%;
  height: 95%;
}

.welcome-message {
  font-size: 50px;
  font-weight: normal;
  margin-bottom: 5px;
  border-bottom: 1px solid black;
}

.description {
  margin-top: 30vh;
  margin-bottom: 50vh;
}

.description-message {
  text-align: left;
  line-height: 20px;
}

footer {
  padding-bottom: 5px;
  display: block;
  font-size: 10px;
  color: grey;
}

.social-media {
  text-align: justify;
}

.social-media a {
  color: black;
}

@media only screen and (max-width: 992px) {
  .side-bar {
    margin-top: 1em;
    margin-left: 10%;
    margin-right: 10%;
    height: 50%;
    text-align: center;
  }

  .description {
    margin-top: 20%;
    margin-bottom: 10%;
  }
}
