nav a {
  text-decoration: none;
  color: grey
}

nav a:visited {
  color: grey;
}

nav ul {
  list-style-type: none;
  text-decoration: none;
}

nav {
  text-align: left;
  font-size: 14px;
}

nav ul li {
  margin-bottom: 5px;
}

nav ul li a .active {
  color: black;
}

.nav-divider {
  font-size: 7px !important;
  margin-left: 2em;
  margin-right: 2em;
  color: gray;
}

@media only screen and (min-width: 375px) {

  .nav-divider {
    font-size: 7px !important;
    margin-left: 1em;
    margin-right: 1em;
    color: gray;
  }
  
}
