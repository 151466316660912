.photos {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.photos_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.photos_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 10px;
}

.photos-grid {
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  height: 100vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.photos-grid::-webkit-scrollbar {
display: none;
}

@media only screen and (min-width: 375px) {
  .photos-grid {
    margin-top: 50px;
    margin-right: 5px;
    margin-left: 5px;
    overflow-y: scroll;
    height: 100vh;
  }

  .photos-grid::-webkit-scrollbar {
    display: none;
  }
}